<template>
  <div class="container-fluid artigos">
    <div class="artigos_wrapper_img">
    <div class="artigos__title">
        <h1>Webinares e treinamentos</h1>
    </div>
    </div>
    <div class="lasDivider"></div>

    <div class="container">
      <div class="row">
      <div class="col-md-3 artigos__search">
        <Chooser @querydb="queryDB($event)"></Chooser>
      </div>
      <div class="col-md-9 artigos__list">
        <div class="row" v-if="artigos.length > 0">
          <template v-for="(art, key) in artigos">
            <div class="col-md-12 artigos__list--content shadow-sm" :key="key">
              <div class="row">
                <div class="col-md-2 image">
                  <template v-if="art.imagem_destaque === null"></template>
                  <template v-else
                    ><img
                      :src="`https://admin.lasbrasil.com${art.imagem_destaque.url}`"
                  /></template>
                </div>
                <div class="col-md-10 text">
                  <div class="row">
                    <div class="col-md-12">
                      <h2>
                        {{ art.titulo }}
                      </h2>
                      <p>{{ art.descricao.substring(0, 250) }}...</p>
                    </div>
                  </div>
                  <div class="row callToaction">
                    <button @click="$router.push(`instrucoes-de-usos/${art._id}`)">Ler mais...</button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      </div>

    </div>
  </div>
</template>

<script>
import { getArtigos } from "../../../services/artigos.service";
import Chooser from "../../../components/Chooser.vue";
import qs from "qs";
export default {
  components: {
    Chooser,
  },
  props: [],
  data() {
    return {
      artigos: [],
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    async queryDB(event) {
      this.artigos = [];
      let fabricante = event.fab;
      let categorias = event.cat;
      console.log(categorias);
      let path;
      let querynofab = qs.stringify({ _where: { categorias: categorias } });
      let queryfab = qs.stringify({ _where: { fabricantes: fabricante } });
      let queryall = qs.stringify({
        _where: { fabricantes: fabricante, categorias: categorias },
      });
      if (fabricante == "") path = `webinares-e-treinamentos?${querynofab}`;
      else if (categorias.length == 0) path = `webinares-e-treinamentos?${queryfab}`;
      else path = `webinares-e-treinamentos?${queryall}`;
      const { data } = await getArtigos(path);
      let artigos_img = data;
      artigos_img = artigos_img.map((val) => {
        val["imagem_destaque"] = val["imagem_destaque"] || null;
        return val;
      });
      this.artigos = artigos_img;
    },
  },
};
</script>
<style lang="scss" scoped>
.artigos {
   padding-left:0px !important;
   padding-right:0px !important;
   .artigos_wrapper_img {
     background-position:center center;
       background-image: linear-gradient(90deg,rgba(13,133,66,0.9) 0%,rgba(32,101,135,0.9) 100%),url('../../../assets/images/lasacademy.jpg')!important;
   .artigos__title {
       display:flex;
       height:50vh;
       margin-top:-140px;
       align-items: center;
       justify-content: center;
       background-color: rgba(255,255,255,0);
       h1 {
           font-size:40px;
       }
   }
   }

   .artigos__search {
       margin-top:30px;
       
   }

  .artigos__list {
     
    .artigos__list--content {
      background-color: #f9f9f9;
      padding: 20px;
      border-radius: 5px;
      margin-top: 30px;
      .image {
        img {
          width: 100%;
        }
      }
      .text {
        .callToaction {
          display: flex;
          justify-content: flex-end;
          padding-right: 30px;
          button {
            width: 150px;
            background-color: #206587 !important;
            color: #fff !important;
            padding: 10px;
          }
        }
      }
    }
  }
}
</style>